.NFTImages {
    display: flex;
    align-items: center;
    justify-content: center;
}
.NFTImage {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-left: -10px;
    border: 1px solid #20201D;
    background-color: #313131;
}

.NFTImage:first-child {
    margin-left: 0;
}

@media only screen and (max-width: 600px) {
    .NFTImage {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin-left: -8px;
    }
}