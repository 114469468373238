.Button {
    user-select: none;
    cursor: pointer;
    border-radius: 80px;
    color: #20201D;
    background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);

    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 48px;
    min-height: 48px;
    padding: 0 15px;
    text-align: center;
    box-sizing: border-box;
}

.Button:hover {
    background: #313131;
}

.Button:hover .Button__text {
    background-color: #C2DCFF;
    background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.Button--disabled {
    opacity: 0.5;
    cursor: default;
    background: #313131;
    color: #7A7A7A;
}
.Button--disabled:hover .Button__text {
    color: #7A7A7A;
    -webkit-text-fill-color: #7A7A7A;
    -moz-text-fill-color: #7A7A7A;
    background: none;
}

@media only screen and (max-width: 420px) {
    .Button {
        height: 44px;
        min-height: 44px;
        padding: 0 14px;
    }
}
