.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 30px 0;
}

.Footer > a:first-child {
  margin-right: 26px;
}

@media only screen and (max-width: 600px) {
  .Footer {
    padding: 10px 0 20px 0;
  }
}